import { Mail } from "lucide-react";

const Footer = () => {
    return (
        <footer className="text-center p-8 text-gray-600 text-lg border-t bg-white/20 backdrop-blur-xl mt-10">
            <p className="mb-4">
                <Mail className="w-6 h-6 inline-block" />
                <a href="mailto:info@myclok.com" className="ml-2 hover:underline">
                    info@myclok.com
                </a>
            </p>
            <p>
                ProblemSpotter by My Clok LLC &copy; {new Date().getFullYear()}
            </p>
        </footer>
    );
}

export default Footer;
