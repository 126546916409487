import { useState } from 'react';
import logoUrl from '../assets/logo.png';
import { useMotionValueEvent, useMotionValue } from 'framer-motion';
import MobileMenu from './MobileMenu';
import { SignedIn, SignInButton, SignedOut, SignOutButton, UserButton, useUser } from "@clerk/clerk-react";

const NavBar = () => {
  const { user, isSignedIn } = useUser();
  const [scrolled, setScrolled] = useState(false);
  const scrollY = useMotionValue(0);
  
  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 0 && !scrolled) {
      setScrolled(true);
    } else if (latest === 0 && scrolled) {
      setScrolled(false);
    }
  });
  
  const defaultClasses = "transition-all absolute inset-0 -z-1";
  const navBarClasses = scrolled 
    ? `${defaultClasses} border-b border-black/10 bg-white/75 backdrop-blur-lg` 
    : `${defaultClasses} bg-transparent`;

  return (
    <div className="sticky inset-x-0 top-0 w-full z-30">
      <div className={navBarClasses}></div>
      <div className="mx-auto w-full max-w-screen-xl px-2.5 lg:px-20 relative">
        <div className="flex items-center justify-between">
          <div>
            <img src={logoUrl} alt="logo" className="h-7 w-auto" />
          </div>
          <nav className="hidden md:block">
            <ul className="flex flex-row space-x-4 p-4">
              <li>
                <a href="#features" className="text-gray-600">Features</a>
              </li>
              <li>
                <a href="#pricing" className="text-gray-600">Pricing</a>
              </li>
            </ul>
          </nav>
          <div className="hidden md:block">
            {isSignedIn ? (
              <>
                <SignOutButton>
                  <button className="bg-black px-4 py-2 rounded-md text-white cursor-pointer">Logout</button>
                </SignOutButton>
                <UserButton />
              </>
            ) : (
              <SignInButton mode="modal" forceRedirectUrl="/dashboard">
                <button className="bg-black px-4 py-2 rounded-md text-white cursor-pointer">Login</button>
              </SignInButton>
            )}
          </div>
          <MobileMenu />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
