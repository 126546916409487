import { title } from "process";

export const features = [
    {
        title: "AI Analysis",
        description: "Every search you'll do will also give you an analysis by our AI system",
    },
    {
        title: "Trends",
        description: "Get the trends of each subredit that you search for, and see how it's doing over time",
    }, 
    {
        title: "Data Visualization",
        description: "We provide data visualization for each subredit you search for",
    }, 
    {
        title: "Feature discovery",
        description: "We will help you identify features, that we think we'll make you progress",

    }, 
    {
        title: "Product discovery",
        description: "We will help you identify products, that we think we'll help you find opportunities you never thought of",
    }
];