import { Check } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';


const PlanCard = ({ price, title, description, features, isPopular, priceId }) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

    const createCheckoutSession = async () => {
        try {
            const stripe = await stripePromise;  // Await the previously initialized promise

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ priceId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const session = await response.json();

            await stripe.redirectToCheckout({ sessionId: session.id });

        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };


    return (
        <div className="plan-card border flex-col justify-between bg-white/20
         backdrop-blur-lg rounded-lg h-full p-6 hover:shadow-md text-left relative">
            {isPopular && <div className="popular-tag absolute
            -top-6 left-0 bg-indigo-500 text-white px-2 py-1 rounded-t-lg w-full text-center">Popular</div>}
            <div>
                <div className="inline-flex items-end">
                    <h1 className="font-extrabold text-3xl">{price}</h1>
                    <span className="ml-3 text-gray-500 align-bottom">/month</span>
                </div>
                <h2 className="font-bold text-xl my-2">{title}</h2>
                <p>{description}</p>
                <div className="border-t border-gray-400 opacity-25 my-3 flex-grow"></div>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-indigo-500 rounded-full flex items-center justify-center mr-2">
                                <Check className="inline-block text-white w-3 h-3" />
                            </div>
                            <p>{feature}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <button
                    onClick={createCheckoutSession}  // Directly call the function without needing to pass priceId again
                    className="bg-indigo-500 hover:bg-indigo-600 py-2 mt-3 rounded-lg text-white w-full"
                >
                    Select Plan
                </button>
            </div>
        </div>
    );
};

export default PlanCard;
