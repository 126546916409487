import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Spinner from './Spinner';  // Import the Spinner component

const Dashboard = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${baseUrl}/api/verify-token`, {
        headers: {
          'x-access-token': token,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (!data.valid) {
            localStorage.removeItem('token');
            // navigate('/login');
          }
        })
        .catch(() => {
          localStorage.removeItem('token');
          // navigate('/login');
        });
    }
  }, [navigate, baseUrl]);

  const searchReddit = async (event) => {
    if (event) event.preventDefault();
    if (!query) return;
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${baseUrl}/search?q=${query}`);
      console.log('Raw response:', response); // Check if the response is received
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      const data = await response.json();
      console.log('Parsed response JSON:', data); // Ensure icon_img is present
      setResults(data);

      const analysisResponse = await fetch(`${baseUrl}/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ posts: data }),
      });

      if (!analysisResponse.ok) {
        throw new Error('Failed to fetch AI analysis');
      }

      const analysisData = await analysisResponse.json();
      console.log('AI analysis:', analysisData);
      setAnalysis(analysisData.analysis);

      localStorage.setItem('searchResults', JSON.stringify(data));
      localStorage.setItem('searchQuery', query);

    } catch (error) {
      console.error('Error during search or analysis:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchReddit(event);
    }
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    localStorage.setItem('searchQuery', newQuery);
  };

  const handleQuestionChange = (e) => {
    setSelectedQuestion(e.target.value);
    setQuery(e.target.value);
    localStorage.setItem('searchQuery', e.target.value);
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#1A2238]">
      {loading && <Spinner />}  {/* Show spinner when loading */}

      <main className={`flex-grow flex flex-col items-center justify-center ${loading ? 'opacity-50' : ''}`}>
        <div className="container mx-auto p-4 bg-white shadow-md rounded">

          <div className="mb-4">
            <select
              value={selectedQuestion}
              onChange={handleQuestionChange}
              className="border p-2 rounded w-full mb-2 text-black"

            >
              <option value="" disabled>Select a question</option>
              <option value="How do I?">How do I?</option>
              <option value="Something I would pay money for?">Something I would pay money for?</option>
              <option value="Does this exist?">Does this exist?</option>
              <option value="Where do I?">Where do I?</option>
              <option value="What are emerging trends?">What are emerging trends?</option>
            </select>
            <input
              type="text"
              id="query"
              value={query}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter keyword"
              className="border p-2 rounded w-full text-black"
            />
            <button
              onClick={searchReddit}
              className="bg-gradient-to-r from-pink-500 via-indigo-600 to-pink-500 text-white p-2 rounded mt-2 w-full"
            >
              Search
            </button>
          </div>

          {error && (
            <div className="mb-4 text-red-500">
              <p>Error: {error}</p>
            </div>
          )}

          {!loading && analysis && (
            <div className="mb-6 bg-gray-50 p-4 rounded shadow">
              <h2 className="text-xl font-semibold mb-4 text-black">AI Analysis</h2>
              <p>{analysis}</p>
            </div>
          )}

          {!loading && results.length > 0 && (
            <div>
              <h2 className="text-xl font-semibold mb-4 text-black">Search Results:</h2>
              <ul>
                {results.map((result, index) => {
                  // Use the URL constructor to handle both relative and absolute URLs
                  const fullUrl = new URL(result.url, 'https://www.reddit.com');

                  return (
                    <li key={index} className="border p-4 rounded mb-4 text-black flex">
                      <div className="mr-4">
                        <img
                          src={result.subreddit_icon_img}
                          alt={`${result.subreddit} icon`}
                          className="h-10 w-10 rounded-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <a
                          href={fullUrl.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-pink-500 text-lg font-semibold"
                        >
                          {result.title}
                        </a>
                        <p className="mt-2 text-gray-700">{result.selftext}</p>
                        <a
                          href={`https://www.reddit.com/r/${result.subreddit}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-pink-500 mt-2"
                        >
                          {result.subreddit}
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {!loading && results.length === 0 && !analysis && !error && (
            <p className="text-gray-600">No results found</p>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Dashboard;
