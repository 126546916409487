import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import { partnersLogo } from './data/partners';
import Slider from './components/Slider';
import RoadMapSection from './components/RoadMapSection';
import FeaturesSection from './components/FeaturesSection';
import TestimonialsSection from './components/TestimonialsSection';
import PricingSection from './components/PricingSection';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        {/* Redirect to Dashboard if the user is authenticated */}
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <Navigate to="/dashboard" />
              </SignedIn>
              <SignedOut>
                <div>
                  <HeroSection />
                  {/* <Slider images={partnersLogo} /> */}
                  {/* <RoadMapSection /> */}
                  <FeaturesSection />
                  {/* <TestimonialsSection /> */}
                  <PricingSection />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Protect the Dashboard route */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
